<template>
  <div class="alumni_page">
    <div class="senate_top">
      <div class="my_banner">
        <img src="../../assets/pc_img/jci_banner.png" alt="">
      </div>
      <div class="banner_content">
        <h3>JCI ALUMNI</h3>
        <p>More than 50 years ago, the JCI Senate was established to recognize the outstanding achievements and service
          of JCI members worldwide.</p>
      </div>
      <topNavBar></topNavBar>
    </div>
    <div class="jci_recognizes">
      <div class="back">
        <img src="../../assets/pc_img/story.png" alt="">
      </div>
      <div class="rec_content">
        <h3 class="title">
          Reconnect to the JCI Movement and share your JCI story.
        </h3>
        <div class="my_content">
          <p>As the worldwide JCI community launches into its second century, the JCI Alumni Program allows former JCI
            members to reconnect and give back to the organization that has created positive change in the lives of so
            many individuals.</p>
          <div class="my_btn">
            <div class="btn_com" @click="toDetail">JOIN JCI ALUMNI</div>
          </div>
        </div>
      </div>
    </div>
    <div class="remembering">
      <div class="right">
        <h3 class="title">
          Reasons to Join JCI Alumni
        </h3>
        <div class="content">
          <div class="item">
            <img src="../../assets/pc_img/give_back.png" alt="">
            <span>GIVE BACK.</span>
            <p>JCI Alumni are encouraged to share and give knowledge and guidance to better their organizations and
              shape the future of JCI. Also, when a former JCI member joins the JCI Alumni Program, their Local and
              National Organizations both benefit by earning credit toward their membership dues. Therefore,
              organizations can put more money toward developing projects to create positive change.</p>
          </div>
          <div class="item">
            <img src="../../assets/pc_img/connect.png" alt="">
            <span>CONNECT GLOBALLY.</span>
            <p>Once a member of the JCI Alumni Program, each member instantly becomes reconnected to a global network
              consisting of millions of people who have also followed the JCI Mission to create impact. JCI Alumni will
              have the opportunity to share their story of impact to inspire members at the local, national or
              international organizational level.</p>
          </div>
          <div class="item">
            <img src="../../assets/pc_img/benefit.png" alt="">
            <span>BENEFIT.</span>
            <p>By becoming part of the JCI Alumni Program, not only do members continue their JCI legacy, but also gain
              access to various incentives. The JCI Alumni Program will offer several activities, resources and benefits
              including a quarterly newsletter, the opportunity to support JCI projects, networking and speaking
              opportunities, exclusive JCI Alumni merchandise and a variety of benefits at JCI International Events.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="senatorship">
      <h3 class="title">
        How to Join JCI Alumni?
      </h3>
      <div class="senatorship_content">
        <p>
          <img src="../../assets/pc_img/checked.png" alt="">
          <span>Be a former JCI member who have exceeded the membership age.</span>
        </p>
        <p>
          <img src="../../assets/pc_img/checked.png" alt="">
          <span>Register online.</span>
        </p>
        <p>
          <img src="../../assets/pc_img/checked.png" alt="">
          <span>Pay the JCI Alumni membership dues.</span>
        </p>
        <div class="sen_btn">
          <div class="btn_com" @click="toDetail">JOIN JCI ALUMNI</div>
        </div>
      </div>
    </div>
    <pageFooter />
  </div>
</template>

<script>
  import pageFooter from "./components/pageFooter";
  import topNavBar from "./components/topNavBar";

  export default {
    components: {
      topNavBar,
      pageFooter
    },
    methods: {
      toDetail() {
        window.open('https://jci.cc/web/#/settings?type=1')
      },
    },
  };
</script>

<style scoped lang='less'>
  .alumni_page {
    .senate_top {
      position: relative;
    }

    .my_banner {
      width: 100%;

      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .banner_content {
      position: absolute;
      top: 50%;
      left: 50%;
      color: #fff;
      text-align: center;
      width: 680px;
      transform: translateX(-50%) translateY(-50%);
      font-size: 20px;

      h3 {
        font-size: 60px;
        margin-bottom: 60px;
      }
    }

    .jci_recognizes {
      position: relative;
      background-color: #f4f4f4;
      width: 100%;

      img {
        width: 100%;
        height: 590px;
      }
    }

    .rec_content {
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      .my_content {
        width: 1000px;
        font-size: 20px;
        text-align: left;
        margin: 80px auto 0;
      }

      .my_btn {
        display: flex;

        div {
          margin-top: 45px;
        }
      }
    }

    .btn_com {
      height: 50px;
      line-height: 50px;
      background: #4266b0;
      border-radius: 25px;
      color: #fff;
      padding: 0 26px;
      margin-right: 20px;
      cursor: pointer;
    }

    .title {
      border-bottom: 4px solid #EDBE38;
      text-align: right;
      padding-bottom: 10px;
      width: 1000px;
      font-size: 32px;
      font-weight: 700;
    }

    .remembering {
      display: flex;
      align-items: center;
      height: 590px;

      .right {
        flex: 1;
        display: flex;
        flex-direction: column;

        .title {
          text-align: left;
          align-self: end;
        }

        .content {
          display: flex;
          margin-top: 50px;
          justify-content: space-around;
          padding: 0 30px;
        }

        .item {
          width: 28%;
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #0D0F30;
        }

        span {
          font-size: 18px;
          margin: 18px 0;
        }

        p {
          font-size: 14px;
        }
      }
    }

    .senatorship {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 500px;
      background-color: #F4F4F4;

      .title {
        padding-right: 200px;
      }

      .senatorship_content {
        width: 1000px;
        margin: 30px auto 0;
        font-size: 20px;
        text-align: left;
      }

      p {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
      }

      span {
        color: #0d0f30;
        font-size: 20px;
        margin-left: 15px;
      }

      .sen_btn {
        display: flex;
        margin-top: 54px;
      }
    }

  }
</style>